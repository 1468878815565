'use client';

import { getFlatNumber } from '@labServices/utils/cmnHelper';
import useAnalytics from './useAnalytics';
import useAuth from '@/contexts/AuthProvider';

const usePixalEvent = () => {
	const { fbEvent, firebaseEvent, mixpanelEvent } = useAnalytics();
	const { user, isLogged } = useAuth();

	const page_view = (payload) => {
		// fbEvent('ViewContent', {
		// 	screen_name: payload,

		// });
		firebaseEvent('page_view', {
			screen_name: payload
		});
	};
	const EventAddToCart = (payload) => {
		const { items, value } = payload;

		fbEvent('AddToCart', {
			content_ids: [items.p_id.toString()], //Array of productIds
			content_name: items.p_name, //String
			content_type: 'product', //String
			contents: [
				{
					id: items.p_id.toString(),
					quantity: items.qty,
					...items
				}
			], //Array of objects
			currency: 'BDT', //String
			value: value //Number
		});
		firebaseEvent('add_to_cart', {
			currency: 'BDT',
			items: [
				{
					item_id: payload.items.p_id,
					item_name: payload.items.p_name,
					price: payload.value
				}
			],
			value: payload.value
		});
		mixpanelEvent('add_to_cart_action', {
			content_type: 'web_product',
			content_id: payload.items.p_id,
			content_name: payload.items.p_name,
			// value_to_sum: getFlatNumber(payload.value),
			quantity: payload.items.qty,
			price: getFlatNumber(payload.value)
		});
	};

	const EventViewContent = (payload) => {
		const { items, value } = payload;
		firebaseEvent('view_item', {
			currency: 'BDT',
			items: [
				{
					item_id: payload.items?.p_id,
					item_name: payload.items?.p_name,
					price: payload?.value
				}
			],
			value: payload.value
		});
		fbEvent('view_item', {
			content_ids: [items?.p_id?.toString()], //Array of productIds
			content_category: items?.p_product_category_id?.toString(), //String
			content_name: items?.p_name, //String
			content_type: 'product',
			contents: [
				{
					...items,
					quantity: 1
				}
			],
			currency: 'BDT',
			value: value
		});
		mixpanelEvent('view_item', {
			content_type: 'web_product',
			content_id: items?.p_id,
			content_name: items?.p_name,
			value: getFlatNumber(value)
		});
	};

	const EventSearch = (payload) => {
		fbEvent('Search', {
			search_string: payload.search
		});
		firebaseEvent('search', {
			search_term: payload.search
		});
		mixpanelEvent('search_term', {
			search_term: payload.search,
			content_type: 'web_product'
		});
	};

	const EventBeginCheckout = (payload) => {
		const { items, value, contents } = payload;
		fbEvent('InitiateCheckout', {
			content_category: 'Product',
			content_ids: contents?.map((e) => e.p_id.toString()),
			contents: contents?.map((e) => {
				return { ...e, id: e.p_id, quantity: e.qty };
			}),
			content_type: 'product',
			currency: 'BDT',
			num_items: contents?.length,
			value: value
		});
		firebaseEvent('begin_checkout', {
			currency: 'BDT',
			items: items,
			coupon: payload.coupon,
			value: value
		});
		mixpanelEvent('initiate_checkout', {
			content_type: 'web_product',
			contents: contents?.map((e) => {
				return { ...e, id: e.p_id, quantity: e.qty };
			}),
			coupon: payload.coupon,
			// hard_copy: payload?.isHardCopyRequired ? 'enable' : 'off',
			value: payload?.value
		});
	};

	const EventPurchase = (payload) => {
		const { items, value, contents } = payload;
		fbEvent('Purchase', {
			content_category: 'Product',
			content_ids: contents?.map((e) => e.p_id.toString()),
			contents: contents?.map((e) => {
				return { ...e, id: e.p_id, quantity: e.qty };
			}),
			content_type: 'product',
			currency: 'BDT',
			num_items: contents?.length,
			value: value
		});
		firebaseEvent('purchase', {
			currency: 'BDT',
			items: items,
			coupon: payload.coupon,
			value: value
		});
		mixpanelEvent('purchase', {
			content_type: 'web_product',
			contents: contents?.map((e) => {
				return { ...e, id: e.p_id, quantity: e.qty };
			}),
			order_id: payload?.order_id,
			// schedule_date: payload?.formattedScheduleDate,
			// schedule_time: payload?.scheduleTimeRange?.en,
			payment_method: payload?.paymentMethod,
			content_count: contents?.length,
			coupon: payload.coupon,
			// hard_copy: payload?.isHardCopyRequired ? 'enable' : 'off',
			value: getFlatNumber(value)
		});
		// mixpanelEvent('purchase', {
		// 	qty: contents?.map((e) => e.qty).reduce((a, b) => a + b, 0),
		// 	user_id: user?.id,
		// 	amount: getFlatNumber(value)
		// });
	};

	const EventBannerAction = (payload) => {
		mixpanelEvent('banner_action', {
			...payload,
			...(isLogged && { user_id: user?.id })
		});
	};
	const EventCategoryAction = (payload) => {
		mixpanelEvent('category_action', {
			...payload,
			...(isLogged && { user_id: user?.id })
		});
	};
	const EventMenuAction = (payload) => {
		mixpanelEvent('menu_action', {
			...payload,
			...(isLogged && { user_id: user?.id })
		});
	};
	const EventBlockAction = (payload) => {
		mixpanelEvent('block_action', {
			...payload,
			...(isLogged && { user_id: user?.id })
		});
	};
	const EventProductBrandAction = (payload) => {
		mixpanelEvent('product_brand_action', {
			...payload,
			...(isLogged && { user_id: user?.id })
		});
	};
	return {
		page_view,
		EventAddToCart,
		EventViewContent,
		EventSearch,
		EventBeginCheckout,
		EventPurchase,
		EventBannerAction,
		EventCategoryAction,
		EventMenuAction,
		EventBlockAction,
		EventProductBrandAction
	};
};

export default usePixalEvent;
